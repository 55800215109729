import applyProdQaConfig from "../../../utils/applyProdQaConfig";

export const eventId = applyProdQaConfig({
  prod: {
    ipd: "e7ee6093-db28-45a0-99a9-cecef9be494f",
    management: "d9e7e133-c06a-4d5b-8f1f-e355b8ef60a3",
    ebooks: "047a78a7-7709-4ec2-8b7d-98e0fbc7b0ea",
  },
  qa: {
    ipd: "a1039278-9df6-4eb5-a4fb-eb8116300208",
    management: "5f5d2a83-05d5-445e-87f5-9e7e341fad8c",
    ebooks: "bbd0e448-fbd8-4436-8244-f2cc9602f1d0",
  },
});

export const gTagId = {
  peaceDay: "AW-881209313/wLgTCM6brvUCEOHfmKQD",
  webinar: "AW-881209313/hSo0CJPGrPUCEOHfmKQD",
};

const config = {
  ref: "B99999999",
  event: eventId.ipd,
  email: null,
  mobile: null,
  city_id: null,
  reg_json: {},
  communication_preference: 1,
};

export default config;
