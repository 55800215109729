/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { Helmet } from "react-helmet";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useState } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Banner from "./component/Banner";
import Heartintune from "./content/Heartintune";
import DrIchak from "./content/DrIchak";
import IdealExecution from "./content/IdealExecution";
import Agenda from "./content/Agenda";
// import "./webinar.css";
import SignUpForm from "./component/SignUpForm";
import Host from "./content/Host";

const Webinar = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <section
      sx={{
        backgroundColor: "#ffff",
        color: "#4A4A49",
        fontFamily:
          "gotham, system-ui, -apple-system, BlinkMacSystemFont, sans-serif",
      }}
    >
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title="International Peace Day Webinar"
      />
      <Header onMenuClick={(e) => scrollTo(`#${e}`)} />
      {showModal && (
        <SignUpForm modalShow={() => setShowModal(false)} webinar />
      )}
      <Banner modalShow={() => setShowModal(true)} webinar />
      <DrIchak modalShow={() => setShowModal(true)} />
      <Box sx={{ bg: "#F2F2F2" }}>
        <Host />
      </Box>
      <Agenda />
      <IdealExecution />
      <Box>
        <Heartintune />
      </Box>
      <Footer />
    </section>
  );
};

export default Webinar;
