/** @jsx jsx */
import {
  jsx,
  Grid,
  Box,
  Radio,
  Label,
  Input,
  Button,
  Checkbox,
  Select,
  Spinner,
} from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import ReactPhoneInput from "react-phone-input-2";
import { inputStyles } from "../../../utils/fabricStyles";
import useForm from "../utils/useRegForm";
import config from "../assets/config";
import country from "../assets/country.json";
import "react-phone-input-2/lib/high-res.css";
import { Modal } from "../../../components/Modal";

const initialValues = {
  ref: config.ref,
  event: config.event,
  pnr: "",
  name: "",
  email: "",
  mobile: config.mobile,
  city_id: config.city_id,
  reg_json: config.reg_json,
  country: "",
  communication_preference: config.communication_preference,
};

function SignUpForm({ modalShow, webinar, setUser }) {
  const {
    handleRadioChange,
    handleSubmit,
    setHasAgreed,
    inputs,
    errors,
    updating,
    hasAgreed,
    regVia,
    setInputData,
    instantValidation,
    setMobileData,
  } = useForm(initialValues, modalShow, webinar, setUser);

  return (
    <Modal
      title=""
      style_container={{ minHeight: "25%", width: "350px", padding: "20px" }}
      style_main={{
        top: "50%",
        height: "100%",
        backgroundColor: "#76AA3A",
        color: "#fff",
        padding: "10px 20px",
        overflow: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Grid
        sx={{
          bg: "#76AA3A",
          color: "#fff",
        }}
        columns={webinar ? 1 : 2}
      >
        {!webinar && (
          <Box>
            <FontAwesomeIcon
              size="3x"
              color="#ffff"
              icon={faPowerOff}
              sx={{ fontSize: ["24px", "38px"] }}
            />
          </Box>
        )}
        <Box
          onClick={() => modalShow()}
          sx={{ textAlign: "end", cursor: "pointer" }}
        >
          X
        </Box>
      </Grid>
      {!webinar ? (
        <Box sx={{ fontSize: ["18px", "25px"], fontWeight: 500 }}>
          Connecting for Peace
          <br />
          PLANeT PEACE
        </Box>
      ) : (
        <Box sx={{ fontSize: ["18px", "24px"], fontWeight: 500, mb: 2 }}>
          Join
          <br />
          <span>Dr. Ichak Kalderon Adizes</span>
        </Box>
      )}
      <div sx={{ fontSize: "20px", display: ["none", "block"] }}>
        Register via:
      </div>
      <Label mt={3} sx={{ fontSize: "16px" }}>
        <Radio
          sx={{ color: "#fff" }}
          name="type"
          value="email"
          onChange={(e) => handleRadioChange(e)}
          defaultChecked
        />
        Email ID
      </Label>
      <Label my="2" sx={{ fontSize: "16px" }}>
        <Radio
          name="type"
          value="mobile"
          sx={{ color: "#fff" }}
          onChange={(e) => handleRadioChange(e)}
        />
        Mobile Number
      </Label>
      <Box mb={2} mt={3}>
        <Box sx={{ width: "100%" }}>
          Name <span sx={{ color: "red" }}> *</span>
        </Box>
        <Box sx={{ width: "100%" }}>
          {" "}
          <Input
            sx={errors.name ? inputStyles.inputError : null}
            name="name"
            id="name"
            value={inputs.name}
            onBlur={() => instantValidation("name")}
            onChange={(e) => setInputData(e)}
          />
          {errors.name && (
            <div sx={inputStyles.errorMessage}>{errors.name}</div>
          )}
        </Box>
      </Box>
      {regVia === "email" && (
        <Box mb="2">
          <Box sx={{ width: "100%" }}>
            Email ID <span sx={{ color: "red" }}> *</span>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Input
              sx={errors.email ? inputStyles.inputError : null}
              name="email"
              id="email"
              value={inputs.email}
              onBlur={() => instantValidation("email")}
              onChange={(e) => setInputData(e)}
            />
            {errors.email && (
              <div sx={inputStyles.errorMessage}>{errors.email}</div>
            )}
          </Box>
        </Box>
      )}
      {regVia === "mobile" && (
        <Box mb="2">
          <Box sx={{ width: "100%" }}>
            Mobile <span sx={{ color: "red" }}> *</span>
          </Box>
          <Box sx={{ width: "100%" }}>
            <ReactPhoneInput
              name="mobile"
              id="mobile"
              value={inputs.mobile}
              onBlur={() => instantValidation("mobile")}
              onChange={(phone) => setMobileData(phone)}
              inputStyle={{
                ...{
                  color: "#ffffff",
                  width: "100%",
                  background: "#76aa3a",
                  height: "45px",
                },
                ...(errors.mobile && inputStyles.inputError),
              }}
              buttonStyle={{
                ...{ color: "#ffffff", background: "#76aa3a" },
                ...(errors.mobile && inputStyles.inputError),
              }}
              dropdownStyle={{ color: "#000000" }}
              country="in"
              enableSearch
              disableSearchIcon={false}
              countryCodeEditable={false}
            />
            {errors.mobile && (
              <div sx={inputStyles.errorMessage}>{errors.mobile}</div>
            )}
          </Box>
        </Box>
      )}
      <Box mb={2} mt={3}>
        <Box sx={{ width: "100%" }}>
          Country <span sx={{ color: "red" }}> *</span>
        </Box>
        <Box sx={{ width: "100%" }}>
          {" "}
          <Select
            name="country"
            id="country"
            value={inputs.country}
            style={errors.country ? inputStyles.inputError : null}
            sx={{ bg: "transparent", outline: "none" }}
            onBlur={() => instantValidation("country")}
            onChange={(e) => setInputData(e)}
          >
            <option value="select your country" sx={{ color: "#4A4A49" }}>
              Select your country
            </option>
            {country.map((data) => (
              <option
                value={data.name}
                sx={{ color: "#4A4A49" }}
                key={data.name}
              >
                {data.name}
              </option>
            ))}
          </Select>
          {errors.country && (
            <div sx={inputStyles.errorMessage}>{errors.country}</div>
          )}
        </Box>
      </Box>
      <Box>
        <Label mb="2" mt="3" sx={{ fontSize: "16px" }}>
          <Checkbox
            sx={{ color: "#fff" }}
            onClick={(e) => setHasAgreed(e.target.checked)}
            // onBlur={() => validate("agree")}
            value={hasAgreed}
          />
          I agree to the Terms of Use and Privacy Policy of Heartfulness
          Institute&apos;s India
        </Label>
        {errors.hasAgreed && !hasAgreed && (
          <div sx={inputStyles.errorMessage}>{errors.hasAgreed}</div>
        )}
      </Box>
      <Box sx={{ textAlign: "center" }}>
        {updating && <Spinner />}
        <Button
          disabled={updating}
          sx={{
            mt: "2",
            color: "#76AA3A",
            backgroundColor: "#fff",
            borderRadius: "0",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={(e) => handleSubmit(e, webinar)}
        >
          {webinar ? "Join Me" : "Sign Up! Be a Peace Messenger"}
        </Button>
      </Box>
      <p sx={{ mb: "200px" }} />
    </Modal>
  );
}

SignUpForm.defaultProps = {
  webinar: false,
  setUser: () => {},
};

SignUpForm.propTypes = {
  modalShow: PropTypes.func.isRequired,
  setUser: PropTypes.func,
  webinar: PropTypes.bool,
};

export default SignUpForm;
