/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Flex } from "theme-ui";
import PropTypes from "prop-types";

function DrIchak({ modalShow }) {
  return (
    <Box
      sx={{
        p: [
          "25px 25px 10px 40px",
          "25px 25px 10px 60px",
          "25px 25px 25px 70px",
        ],
        height: "auto",
        maxWidth: ["100%", "90%", "80%"],
        margin: "0 auto",
        mt: [0, null, "120px"],
      }}
    >
      <Box>
        {/* <Box>
          <Box sx={{ float: ["none", null, "right"] }}>
            <StaticImage
              src="../assets/img/Ichak.png"
              sx={{
                height: "auto",
                width: ["150px", null, "300px"],
                mt: [0, null, "-120px"],
              }}
              placeholder="blurred"
              alt="Logo"
            />
          </Box>
          <Box sx={{ float: ["none", null, "left"] }}>
            <h3 sx={{ color: "#76AA3A", fontSize: ["18px", "18px", "28px"] }}>
              Dr. Ichak Kalderon Adizes
            </h3>
            <b sx={{ fontSize: ["16px", "16px", "18px"] }}>
              Thought leader, renowned management professor, and best selling
              author.
            </b>
          </Box>
        </Box> */}
        <Flex
          sx={{
            flexDirection: ["column", null, "row-reverse"],
            justifyContent: "start",
          }}
        >
          <StaticImage
            src="../assets/img/Ichak.png"
            sx={{
              height: "auto",
              width: ["150px", null, "300px"],
              mt: [0, null, "-120px"],
            }}
            placeholder="blurred"
            alt="Logo"
          />
          <Box>
            <h3 sx={{ color: "#76AA3A", fontSize: ["18px", "18px", "28px"] }}>
              Dr. Ichak Kalderon Adizes
            </h3>
            <b sx={{ fontSize: ["16px", "16px", "18px"] }}>
              Thought leader, renowned management professor, and best selling
              author.
            </b>
          </Box>
        </Flex>
        <Box sx={{ fontSize: "16px", paddingBottom: ["20px", "30px", "60px"] }}>
          <p>
            Leadership Excellence magazine named Dr. Adizes one of the Top
            Thirty Thought Leaders on Leadership, and The Holmes Report named
            him as one of the Best Communicators Among World Leaders for 2017
            alongside Pope Francis, Angela Merkel, and the Dalai Lama.
            <br />
            <br /> For more than forty years, Dr. Ichak Kalderon Adizes has
            developed, tested, and documented a methodology for Organizational
            transformation and provides tools for corporations, governments, and
            not-for-profit organizations to achieve exceptional results and
            manage accelerated change without destructive conflict.
            <br />
            <br />{" "}
            <span
              onClick={() => modalShow()}
              role="button"
              tabIndex={0}
              onKeyDown={() => modalShow()}
              sx={{
                color: "#76AA3A",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Join him on 21st September
            </span>
            , the International Day of Peace to understand how the world changes
            when individuals change. Listen to him share his ideas on individual
            transformation and the ideal qualities in a manager of the future.
          </p>
          <p sx={{ textAlign: "right" }}>
            <a
              sx={{
                color: "#76AA3A",
                cursor: "pointer",
                textDecoration: "underline",
                mt: 2,
              }}
              rel="noreferrer"
              href="https://www.ichakadizes.com/"
              target="_blank"
            >
              Know More
            </a>
          </p>
        </Box>
      </Box>
    </Box>
  );
}

DrIchak.propTypes = {
  modalShow: PropTypes.func.isRequired,
};
export default DrIchak;
