/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Flex } from "theme-ui";

const IdealExecution = () => {
  const navigateToAmazon = () => {
    window.open(
      "https://smile.amazon.com/Ideal-Executive-Ichak-Kalderon-Adizes/dp/0937120030/ref=asc_[…]phy=9010754&hvtargid=pla-568493205046&psc=1&sa-no-redirect=1"
    );
  };
  return (
    <Flex
      sx={{
        height: ["auto", null, "auto"],
        justifyContent: "space-between",
        flexDirection: ["column", null, "row"],
        maxWidth: ["100%", "90%", "80%"],
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          p: [
            "50px 25px 25px 40px",
            "60px 25px 25px 60px",
            "60px 25px 25px 70px",
          ],
          maxWidth: "520px",
          maxHeight: "auto",
          margin: "auto 0",
        }}
      >
        <p>
          All participants who send in a question before or during the seminar,
          will receive a copy of his best selling book
        </p>
        <h2 sx={{ color: "#0082BE", fontSize: "35px" }}>
          &quot;The Ideal Executive&quot;
        </h2>
        <p>
          The Ideal Executive is a best seller worldwide. It deals with how to
          build a complementary team based on mutual trust and respect. And
          there is no peace without respect for differences. The Ideal Executive
          teaches how to analyze the strengths and weaknesses of individual
          management styles and the best way to build an organization&apos;s
          support structure using this method.
        </p>
      </Box>
      <Box
        sx={{
          maxWidth: ["480px", "650px"],
          maxHeight: ["400px", "auto"],
          margin: "auto 0",
          position: "relative",
        }}
      >
        <StaticImage
          src="../assets/img/Book_Image.png"
          placeholder="blurred"
          alt="Logo"
          sx={{
            maxWidth: ["480px", "650px"],
            maxHeight: ["400px", "auto"],
            margin: "auto 0",
          }}
        />
        <StaticImage
          src="../assets/img/Amazon_download.png"
          placeholder="blurred"
          alt="Logo"
          onClick={() => navigateToAmazon()}
          sx={{
            position: "absolute",
            width: ["80px", null, "110px"],
            height: ["39px", null, "52px"],
            bottom: "20px",
            right: 0,
            cursor: "pointer",
          }}
        />
      </Box>
    </Flex>
  );
};

export default IdealExecution;
