/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Flex } from "theme-ui";

const Host = () => {
  return (
    <Box
      sx={{
        p: [
          "20px 25px 10px 40px",
          "20px 25px 10px 60px",
          "20px 25px 25px 70px",
        ],
        height: "auto",
        maxWidth: ["100%", "90%", "80%"],
        margin: "0 auto",
        mt: 0,
      }}
    >
      <Flex
        sx={{
          flexDirection: ["column", null, "row"],
          justifyContent: "space-between",
        }}
      >
        <StaticImage
          src="../assets/img/Mills.png"
          sx={{
            height: ["auto", "auto", "fit-content"],
            width: ["150px", null, "300px"],
            mt: 0,
          }}
          placeholder="blurred"
          alt="Logo"
        />
        <Box
          sx={{
            width: ["100%", null, "calc(100% - 300px)"],
            alignSelf: "flex-end",
          }}
        >
          <b sx={{ fontSize: ["16px", "16px", "18px"] }}>Host</b>
          <h3 sx={{ color: "#0082BE", fontSize: ["18px", "18px", "28px"] }}>
            Chris Mills
          </h3>
          <p sx={{ fontSize: "16px" }}>
            Chris Mills is a practitioner, instructor and student of
            Heartfulness Meditation, which he has been practicing for nearly
            three decades. As a meditation trainer, Chris has travelled
            throughout North America and Europe facilitating meditation
            workshops to enhance innovation, creativity, and conscious living.
            Chris also consults with businesses on development of relaxation and
            meditation programs as part of their overall health & wellness
            initiatives.
          </p>
        </Box>
      </Flex>
      <p sx={{ fontSize: "16px" }}>
        Professionally, Chris is an advertising and marketing veteran and is
        Director of Marketing at Anthem. You can read an interview with Chris in
        Heartfulness Magazine, where he shares his ideas on bringing heart back
        into corporate culture.
      </p>
      <p sx={{ textAlign: "right" }}>
        <a
          sx={{
            color: "#0082BE",
            cursor: "pointer",
            textDecoration: "underline",
            mt: 2,
          }}
          rel="noreferrer"
          href="https://www.heartfulnessmagazine.com/wax-on-wax-off/"
          target="_blank"
        >
          Read More
        </a>
      </p>
    </Box>
  );
};

export default Host;
