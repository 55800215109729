/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
// import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Agenda = () => {
  return (
    <Flex
      sx={{
        p: ["0 25px 0 40px", "0 25px 0 60px", "45px 25px 45px 70px"],
        background: "#f9f9f9",
        width: "100%",
        justifyContent: ["space-between", null, null, null, "center"],
        flexDirection: ["column", null, "row"],
      }}
    >
      <Box
        sx={{
          lineHeight: "116px",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          width: "200px",
          color: "#0082BE",
          position: "relative",
          borderRight: ["none", null, "1px solid #f0f0f0"],
          // zIndex: 3,
          // "::after": {
          //   variant: "agendaShadow"
          // }
        }}
      >
        Agenda
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          pl: "50px",
          pr: "5px",
          borderRight: ["none", null, "1px solid #f0f0f0"],
          height: "170px",
        }}
      >
        <Box sx={{ width: "200px", position: "relative" }}>
          <StaticImage
            src="../assets/img/Icons/video-conference.png"
            placeholder="blurred"
            sx={{ width: "60px", mb: 2 }}
            alt="Logo"
          />
          <br />
          <b sx={{ padding: "10px 0 0 0", fontSize: "18px" }}>2:45 pm EST</b>
          <p>Online Conference begins</p>
        </Box>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          pl: "50px",
          pr: "10px",
          borderRight: ["none", null, "1px solid #f0f0f0"],
          height: "190px",
        }}
      >
        <Box sx={{ width: "200px", position: "relative" }}>
          <StaticImage
            src="../assets/img/Icons/audience.png"
            placeholder="blurred"
            sx={{ width: "60px", mb: 2 }}
            alt="Logo"
          />
          <br />
          <b>3:00 pm EST</b>
          <p>Audience welcome & guided peace intention</p>
        </Box>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          pl: "50px",
          pr: "5px",
          borderRight: ["none", null, "1px solid #f0f0f0"],
          height: "170px",
        }}
      >
        <Box sx={{ width: "200px", position: "relative" }}>
          <StaticImage
            src="../assets/img/Icons/Talk.png"
            placeholder="blurred"
            sx={{ width: "60px", mb: 2 }}
            alt="Logo"
          />
          <br />
          <b>3:15 pm EST</b>
          <p>Talk by Dr.Adizes</p>
        </Box>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          pl: "50px",
          pr: "5px",
          height: "170px",
        }}
      >
        <Box sx={{ width: "200px", position: "relative" }}>
          <StaticImage
            src="../assets/img/Icons/QandA.png"
            placeholder="blurred"
            sx={{ width: "60px", mb: 2 }}
            alt="Logo"
          />
          <br />
          <b>3:45 pm EST</b>
          <p>Q&A</p>
        </Box>
      </Box>
    </Flex>
  );
};

export default Agenda;
