import { useState } from "react";
import { validateEmail } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import getMandatoryEnv from "../../../utils/getMandatoryEnv";
import generatePNR from "../../../utils/generatePNR";
import useAlert from "../../../components/Alert/useAlert";
// import { validatePhoneNumber } from "../../../utils/validations";
import { eventId, gTagId } from "../assets/config";

const { mySrcmConfig } = getMandatoryEnv(["mySrcmConfig"]);

const { eventsClient } = mySrcmConfig;

const CryptoJS = require("crypto-js");

export default function useForm(initialValues, modalShow, webinar, setUser) {
  const { showAlert } = useAlert();
  const [inputs, setInputs] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [updating, setUpdating] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [regVia, setRegVia] = useState("email");

  const validate = () => {
    const errorData = {};
    // Email
    if (inputs.communication_preference === 1) {
      if (!inputs.email) {
        errorData.email = "Email is required";
      } else if (!validateEmail(inputs.email)) {
        errorData.email = "Invalid email address";
      }
    }

    // Mobile
    if (inputs.communication_preference === 2) {
      if (!inputs.mobile) {
        errorData.mobile = "Mobile number is required";
      }

      // else if (!validatePhoneNumber(inputs.mobile)) {
      //   errorData.mobile = "Invalid mobile number";
      // }
    }

    // Name
    if (!inputs.name) {
      errorData.name = "Name is required";
    }

    // Country
    if (!inputs.country) {
      errorData.country = "Country is required";
    }

    // Agree
    if (!hasAgreed) {
      errorData.hasAgreed = "Please agree to the Terms of use & Privacy policy";
    }
    setErrors(errorData);
    return errorData;
  };

  const instantValidation = (type = "") => {
    const errorData = {};
    if (type === "email") {
      if (!inputs.email) {
        errorData.email = "Email is required";
      } else {
        delete errorData.email;
      }
      if (inputs.email && !validateEmail(inputs.email)) {
        delete errorData.email;
      }
    }

    if (type === "mobile") {
      if (!inputs.mobile) {
        errorData.mobile = "Mobile number is required";
      } else {
        delete errorData.mobile;
      }
      // if (inputs.mobile && !validatePhoneNumber(inputs.mobile)) {
      //   errorData.mobile = "Invalid mobile number";
      // }
    }

    if (type === "name") {
      if (!inputs.name) {
        errorData.name = "Name is required";
      } else {
        delete errorData.name;
      }
    }

    if (type === "country") {
      if (!inputs.country) {
        errorData.country = "Country is required";
      } else {
        delete errorData.country;
      }
    }

    if (type === "agree") {
      if (!hasAgreed) {
        errorData.hasAgreed =
          "Please agree to the Terms of use & Privacy policy";
      }
    }
    setErrors(errorData);
    return errorData;
  };

  const gtag = (type) => {
    let awConversionId = gTagId.peaceDay;
    if (type === true) {
      awConversionId = gTagId.webinar;
    }

    if (typeof window !== "undefined" && "gtag" in window && awConversionId) {
      if (typeof awConversionId === "string") {
        window.gtag("event", "conversion", {
          send_to: awConversionId,
          transaction_id: "",
        });
      }
    }
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();
    const validationErrors = validate();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    const afterUpdate = () => {
      setHasAgreed(false);
      setInputs(initialValues);
      setUpdating(false);
      modalShow();
    };
    if (noErrors) {
      setUser(!webinar ? inputs : "");
      setUpdating(true);
      inputs.pnr = generatePNR(inputs.name);
      inputs.has_registered = true;
      inputs.event = webinar ? eventId.management : eventId.ipd;
      inputs.reg_json = { country: inputs.country };
      const payload = JSON.stringify(inputs);
      const clientId = eventsClient;
      const hashText = CryptoJS.HmacSHA256(payload, clientId);
      fetch(`${mySrcmConfig.profileServer}/api/v2/event-registrations/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "hmac-token": `${hashText}`,
          "x-client-id": `${clientId}`,
        },
        body: payload,
      })
        .then((response) => response.json())
        .then((registrationResponse) => {
          if ("id" in registrationResponse) {
            gtag(type);
            const alert = {
              title: "Success",
              message: `You have been successfully registered.`,
              confirm_text: "Okay",
              is_blocking: true,
              has_registered: true,
            };
            modalShow();
            showAlert(alert);
            return afterUpdate();
          }
          const alert = {
            title: "Error",
            message:
              "Error:  Registration failed. Something went wrong. Please try again later.",
            confirm_text: "Okay",
            is_blocking: true,
          };
          modalShow();
          showAlert(alert);
          return afterUpdate();
        });
    }
  };

  const setInputData = (event) => {
    setInputs((inputD) => ({
      ...inputD,
      [event.target.name]: event.target.value,
    }));
  };

  const setMobileData = (phone) => {
    setInputs((inputM) => ({
      ...inputM,
      mobile: `+${phone}`,
    }));
  };

  const handleRadioChange = (event) => {
    if (event.target.value === "email") {
      setInputs((prevState) => ({
        ...prevState,
        mobile: null,
        communication_preference: 1,
      }));
    } else if (event.target.value === "mobile") {
      setInputs((prevState) => ({
        ...prevState,
        email: null,
        communication_preference: 2,
      }));
    }
    setRegVia(event.target.value);
  };

  return {
    handleSubmit,
    handleRadioChange,
    setHasAgreed,
    inputs,
    errors,
    updating,
    hasAgreed,
    regVia,
    setInputData,
    validate,
    instantValidation,
    setMobileData,
  };
}
